import { render, staticRenderFns } from "./IsThisYou.vue?vue&type=template&id=7e334cf0&scoped=true&"
import script from "./IsThisYou.vue?vue&type=script&lang=js&"
export * from "./IsThisYou.vue?vue&type=script&lang=js&"
import style0 from "./IsThisYou.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./IsThisYou.vue?vue&type=style&index=1&id=7e334cf0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7e334cf0",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VForm } from 'vuetify/lib/components/VForm';
import { VImg } from 'vuetify/lib/components/VImg';
installComponents(component, {VAvatar,VBtn,VCardText,VForm,VImg})
