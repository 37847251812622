/* eslint-disable no-confusing-arrow */
/* eslint-disable new-cap */
import { ref, computed } from '@vue/composition-api'
import useCryptoJs from '@core/utils/useCryptoJs'

export default function useLogData() {
  const { encrypt, decrypt } = useCryptoJs()

  // loginRoute => LOGIN_PAGE | VERIFICATION_CODE_PAGE
  const logData = ref({
    code: null,
    exists: null,
    fullPhone: null,
    joinGame: null,
    loginRoute: null,
    matchCode: null,
    password: null,
    phoneCode: null,
    phoneNumber: null,
    register: {},
    responseIsThisYou: null,
    type: null,
    typeIsThisYou: null,
    username: null,
    redirect: null,
    facility: {},
    field: null,
    date: null,
    time: null,
  })

  const updateLogData = async log => {
    const encryptLogData = encrypt(log)
    await localStorage.setItem('logData', JSON.stringify(encryptLogData))
  }

  const currentLogData = computed(() => {
    const encryptLogData = localStorage.getItem('logData')
    if (encryptLogData) {
      const decryptLogData = decrypt(JSON.parse(encryptLogData))

      return JSON.parse(decryptLogData)
    }

    return encryptLogData
  })

  const clearLogData = async () => {
    Object.keys(logData.value).forEach(i => { logData.value[i] = null })
    logData.value.register = {}
    await localStorage.removeItem('logData')
  }

  return {
    // data
    logData,

    // computed
    currentLogData,

    // methods
    updateLogData,
    clearLogData,
  }
}
